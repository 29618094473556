<template lang="pug">
b-checkbox.is-b-checkbox(
  :value="value"
  :native-value="nativeValue"
  :type="type"
  :disabled="disabled"
  :true-value="trueValue"
  :false-value="falseValue"
  :size="size"
  @input="inputHandler"
  :class="containerClass"
)
  slot
</template>

<script setup lang="ts">
import { computed } from 'vue'

const TYPE_MAP = [
  'is-ci',
  'is-white',
  'is-black',
  'is-light',
  'is-dark',
  'is-primary',
  'is-info',
  'is-success',
  'is-warning',
  'is-danger'
] as const

type Primitive = boolean | string | number
type TypeMap = (typeof TYPE_MAP)[number]

const props = withDefaults(
  defineProps<{
    type?: TypeMap
    value?: Primitive | Primitive[]
    nativeValue?: string | number
    trueValue?: Primitive
    falseValue?: Primitive
    disabled?: boolean
    size?: 'is-small' | 'is-medium' | 'is-large'
  }>(),
  {
    type: 'is-primary'
  }
)

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return [props.type].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.is-b-checkbox.checkbox
  cursor: pointer
  &.bordered
    border: 1px solid color.$concrete

  &.is-ci
    &:hover input[type=checkbox]:not(:disabled) + .check
      border-color: color.$ci
    input[type=checkbox]:checked+.check
      border-color: color.$ci
      background-color: color.$ci

  &.is-info
    &:hover input[type=checkbox]:not(:disabled) + .check
      border-color: color.$info
    input[type=checkbox]:checked+.check
      border-color: color.$info
      background-color: color.$info
</style>
