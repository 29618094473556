<template lang="pug">
b-checkbox-button.k-checkbox-is-b-checkbox-button(
  :value="value"
  :native-value="nativeValue"
  :disabled="disabled"
  :size="size"
  :type="type"
  @input="inputHandler"
  :class="containerClass"
)
  slot
</template>

<script setup lang="ts">
import { computed } from 'vue'

const TYPE_MAP = {
  'is-ci': 'is-ci',
  'is-white': 'is-white',
  'is-black': 'is-black',
  'is-light': 'is-light',
  'is-dark': 'is-dark',
  'is-primary': 'is-primary',
  'is-info': 'is-info',
  'is-success': 'is-success',
  'is-warning': 'is-warning',
  'is-danger': 'is-danger'
} as const

type Primitive = boolean | string | number

const props = withDefaults(
  defineProps<{
    type?: keyof typeof TYPE_MAP
    value?: Primitive | Primitive[]
    nativeValue?: string | number
    trueValue?: boolean | string | number
    falseValue?: boolean | string | number
    disabled?: boolean
    size?: 'is-small' | 'is-medium' | 'is-large'
  }>(),
  {}
)

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return [TYPE_MAP[props.type]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-checkbox-is-b-checkbox-button
  .checkbox
    &.is-ci
      color: color.$white
      border-color: color.$ci
      background-color: color.$ci
</style>
