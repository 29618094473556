<template lang="pug">
div(
  :is="component"
  :type="type"
  :value="value"
  :native-value="nativeValue"
  :true-value="trueValue"
  :false-value="falseValue"
  :disabled="disabled"
  :size="size"
  @input="inputHandler"
)
  slot
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import isBCheckbox from './k_checkbox/is-b-checkbox.vue'
import isBCheckboxButton from './k_checkbox/is-b-checkbox-button.vue'
import IsAnimateCheck from './k_checkbox/is-animate-check.vue'

const VARIANT_MAP = {
  'is-b-checkbox': isBCheckbox,
  'is-b-checkbox-button': isBCheckboxButton,
  'is-animate-check': IsAnimateCheck
} as const

type Primitive = boolean | string | number

const props = withDefaults(
  defineProps<{
    variant?: keyof typeof VARIANT_MAP
    type?: string
    value?: Primitive | Primitive[]
    nativeValue?: string | number
    trueValue?: boolean | string | number
    falseValue?: boolean | string | number
    size?: string
    disabled?: boolean
  }>(),
  {
    variant: 'is-b-checkbox',
    trueValue: true,
    falseValue: false
  }
)

const emit = defineEmits(['input'])

watch(
  () => props.value,
  () => {
    // If value is an array, nativeValue must be defined and included in the array
    if (Array.isArray(props.value) && props.nativeValue == null) {
      console.warn(
        `[k-checkbox] nativeValue is required when value is an array`
      )
    }
  }
)

const inputHandler = (value) => {
  emit('input', value)
}

const component = computed(() => {
  return VARIANT_MAP[props.variant]
})
</script>
