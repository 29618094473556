import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaigns',
  attributes: [
    'id',
    'fee',
    'rate_type',
    'address',
    'full_address',
    'host_check_in_token',
    'host_check_in_token_expired_at',
    'published_platforms',
    'draft_due_date',
    'revision_date',
    'release_date',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
  ]
}

const PLATFORM_MAP = {
  instagram: ['post', 'reel', 'story', 'highlight', 'live', 'collab_post', 'hashtag'],
  facebook: ['post', 'story', 'live', 'reel'],
  youtube: ['video', 'short', 'live', 'community_post'],
  tiktok: ['video', 'live', 'story'],
  dcard: ['post']
}

export default class PromoterCampaign extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static platformNames() {
    return Object.keys(PLATFORM_MAP)
  }

  static contentTypesFor(platformName) {
    return PLATFORM_MAP[platformName]
  }
}
