<template lang="pug">
.vc-k-field-group(:class="containerClass")
  .input-wrapper
    slot
  .delete-button(
    v-if="deletable"
    @click="deleteHandler"
  )
    .icon
      i.fa.fa-close
</template>

<script setup lang="ts">
import { computed } from 'vue'

const STYLE_TYPE_MAP = {
  'shadow-box': 'box',
  'line-border-box': 'line-border-box',
  'no-border': 'no-border'
}

const props = withDefaults(
  defineProps<{
    styleType?: string
    deletable?: boolean
    compact?: boolean
  }>(),
  {
    styleType: () => 'shadow-box'
  }
)

const emit = defineEmits(['click-delete'])

function deleteHandler() {
  emit('click-delete')
}

const containerClass = computed(() => {
  return [STYLE_TYPE_MAP[props.styleType], props.compact ? 'compact' : null]
    .filter(Boolean)
    .join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/color'

.vc-k-field-group
  position: relative
  padding: size.$gap
  margin-bottom: size.$gap
  border-radius: 8px

  &.compact
    margin-bottom: 0
    padding: size.$gap * 0.5

    &:last-of-type
      margin-bottom: size.$gap

  &.line-border-box
    border: 1px solid color.$lighter

  > .delete-button
    position: absolute
    top: -.5rem
    width: 1.5rem
    height: 1.5rem
    color: color.$white
    text-align: center
    border-radius: 100%

    right: size.$gap * -1
    background-color: color.$danger
    box-shadow: 0 0 2px color.$dark
    transition: box-shadow .3s
    cursor: pointer
    &:hover
      box-shadow: 0 0 5px color.$black
</style>
