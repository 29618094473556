<template lang="pug">
b-input.k-input-is-b-input(
  :value="value"
  :placeholder="placeholder"
  :type="type"
  :size="size"
  :expanded="expanded"
  :disabled="disabled"
  :icon="icon"
  :icon-right="iconRight"
  @input="handleInput"
)
  slot
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  value: { type: [String, Number], required: false },
  type: { type: String, required: false },
  size: { type: String, required: false },
  placeholder: { type: String, required: false },
  expanded: { type: Boolean, required: false },
  disabled: { type: Boolean, required: false },
  icon: { type: String, required: false },
  iconRight: { type: String, required: false }
})

const emit = defineEmits(['input'])

function handleInput(value) {
  emit('input', value)
}
</script>
